import { COMMON } from '@/constants/store.constant';
import { getUsers, updateUser, createUser, deleteUser } from '@/services/api/user.service';
import { getTenants } from '@/services/api/tenant.service';

const initialState = () => ({
  users: [],
  filterUsers: [],
  tenants: [],
  params: {
    page: 0,
    size: 40,
    name: null,
    tenants: null,
  },
  isLastPage: false,
  shouldRefresh: false,
  totalElements: 0,
});

const filterUsersByTerm = (users, filterText) => {
  return filterText === null ? users : users.filter((user) => user.fullName.toLowerCase().includes(filterText.toLowerCase()) ||
  user.userName.toLowerCase().includes(filterText.toLowerCase()) || user.email.toLowerCase().includes(filterText.toLowerCase()));
};

const filterByTenant = (users, filterTenants) => {
  if (!filterTenants) {
    return users;
  }

  const tenantsArray = filterTenants.split(',').map((tenant) => tenant.trim());

  return users.filter((user) =>
    user.tenants?.some((tenant) => tenantsArray.includes(tenant.code))
  );
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    [COMMON.UPDATE_STATE] (state, { name, value }) {
      state[name] = value;
    },
    [COMMON.UPDATE_PARAM] (state, { name, value }) {
      state.params[name] = value;
    },
    [COMMON.RESET_STATE] (state) {
      Object.assign(state, initialState());
    },
    async ADD_TENANT_TO_USER (state, { userId, tenantId }) {
      const tenant = state.tenants.find((tenant) => tenant.code === tenantId);
      const user = state.users.find((user) => user.id === userId);
      if (user && !user.tenants.some((t) => t.code === tenant.code)) {
        user.tenants.push(tenant);
      }
      await updateUser(user);
    },
    async REMOVE_TENANT_FROM_USER (state, { userId, tenantId }) {
      const user = state.users.find((user) => user.id === userId);
      if (user) {
        user.tenants = user.tenants.filter((tenant) => tenant.id !== tenantId);
      }
      await updateUser(user);
    },
    ADD_USER (state, newUser) {
      state.users.push(newUser);
      state.filterUsers = state.users;
    },
    UPDATE_USER (state, updatedUser) {
      const index = state.users.findIndex((user) => user.id === updatedUser.id);
      if (index !== -1) {
        state.users.splice(index, 1, updatedUser);
      }
      state.filterUsers = state.users;
    },
    DELETE_USER (state, userId) {
      state.users = state.users.filter((user) => user.id !== userId);
      state.filterUsers = state.users;
    },
  },
  actions: {
    async [COMMON.FETCH_DATA] ({ state, commit }, { shouldReset }) {
      commit(COMMON.UPDATE_PARAM, { name: 'page', value: shouldReset ? 0 : state.params.page + 1 });
      const { data: users } = await getUsers();
      const { data: tenants } = await getTenants();
      commit(COMMON.UPDATE_STATE, { name: 'users', value: users });
      commit(COMMON.UPDATE_STATE, { name: 'tenants', value: tenants });
      commit(COMMON.UPDATE_STATE, { name: 'filterUsers', value: users });
      commit(COMMON.UPDATE_STATE, { name: 'isLastPage', value: true });
    },
    [COMMON.FILTER_DATA] ({ state, commit }) {
      const data = filterByTenant(filterUsersByTerm(state.users, state.params.name), state.params.tenants);
      commit(COMMON.UPDATE_STATE, { name: 'filterUsers', value: data });
    },
    addTenantToUser ({ commit }, { userId, tenantId }) {
      commit('ADD_TENANT_TO_USER', { userId, tenantId });
    },
    removeTenantFromUser ({ commit }, { userId, tenantId }) {
      commit('REMOVE_TENANT_FROM_USER', { userId, tenantId });
    },
    async createUser ({ commit, dispatch }, newUser) {
      const { data: createdUser } = await createUser(newUser);
      commit('ADD_USER', createdUser);
      dispatch(COMMON.FILTER_DATA);
    },
    async updateUser ({ commit, dispatch }, userData) {
      const { data: updatedUser } = await updateUser(userData);
      commit('UPDATE_USER', updatedUser);
      dispatch(COMMON.FILTER_DATA);
    },
    async deleteUser ({ commit, dispatch }, { id }) {
      await deleteUser(id);
      commit('DELETE_USER', id);
      dispatch(COMMON.FILTER_DATA);
    },
  },
  getters: {
    [COMMON.GET_STATE] (state) {
      return state.filterUsers;
    },
    [COMMON.IS_LAST_PAGE] (state) {
      return state.isLastPage;
    },
  },
};
